body {
	margin: 0;
	font-family: 'Questrial', sans-serif;
	-webkit-user-select: 'none';
	-moz-user-select: 'none';
	-ms-user-select: 'none';
	user-select: 'none';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Prompt', sans-serif;
	font-weight: 'light';
	letter-spacing: 0.1em;
	margin: 0;
	max-width: max-content;
}

h6:hover {
	font-style: italic;
}

a {
	text-decoration: none;
	color: #151515;
	cursor: pointer;
	max-width: max-content;

	& > p:hover {
		font-style: italic;
		color: #aaaaaa;
		cursor: pointer;
	}
}

.app {
	overflow: auto;
}

.home {
	min-height: 100vh;
}

.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.navbar {
	.transparent {
		background-color: transparent;
	}

	.white {
		background: linear-gradient(white, 92%, transparent);
	}

	.wrapper {
		width: 80%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		height: 10vh;
	}

	.section {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 3vh;
		:hover {
			cursor: pointer;
		}
	}

	.icon {
		font-size: 1.5rem;
		padding-left: 0.5rem;
	}
}

.intro {
	padding: 0 5rem;

	& > p {
		text-decoration: none;
		color: #151515;
	}

	.period {
		font-size: 1.5em;
	}

	.main {
		display: flex;
		justify-items: space-around;
		align-items: center;
		flex-wrap: wrap;
	}

	.segment {
		width: 300px;
		margin: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.avatar {
		height: 100%;
		border-radius: 50%;
	}
}

.heading {
	h1,
	h3 {
		&:hover {
			font-style: italic;
			color: #aaaaaa;
			cursor: pointer;
		}
	}
}

.period {
	font-size: 1.5em;
}

.work {
	position: relative;
	height: 100vh;

	.section {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 80%;
		margin-bottom: 40px;
	}

	h1 {
		font-size: 3rem;
	}

	.item {
		display: flex;
		justify-content: center;
		align-items: center;
		&.card {
			flex-direction: column;
			max-width: 25vw;
			margin: 1rem;
		}
		&.cards {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	img {
		width: 25vw;
		border: 2px solid #aaaaaa;
		border-radius: 4px;
		margin-bottom: 10px;
	}
}

.logo {
	color: #151515;
	margin: 0 0 7vh 0;
	cursor: pointer;

	&.open {
		color: white;

		&:hover {
			font-style: italic;
			color: #aaaaaa;
			cursor: pointer;
		}
	}
}

.about {
	display: flex;
	justify-content: center;
	align-items: center;

	.section {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.5rem;
		width: 60%;
		line-height: 3rem;

		a {
			display: inline-flex;
			color: #151515;
			text-decoration: none;
			margin-left: 4px;
			vertical-align: middle;

			&:hover {
				color: #aaaaaa;
			}
		}

		@media screen and (max-width: 600px) {
			width: 70%;
			line-height: 2rem;
			font-size: 1rem;
		}
	}

	h1 {
		font-size: 3rem;
	}
}

.footer {
	height: max-content;
	width: 100vw;
	background-color: #e9e9e9;
	padding-bottom: 1rem;
	position: absolute;
	bottom: 0;

	.icon {
		font-size: 2rem;
		text-decoration: none;
		color: #151515;
		padding: 1rem 1rem;
		&:hover {
			color: white;
		}
	}
}

.menu {
	background-color: #2b2b2b;
	overflow: auto;

	h1 {
		color: white;
	}

	.section {
		display: flex;
		flex-direction: column;
		font-size: 3rem;

		@media screen and (max-width: 600px) {
			font-size: 2rem;
		}
		@media screen and (max-width: 300px) {
			font-size: 1.5rem;
		}
	}
}

.contact {
	.section {
		display: flex;
		flex-direction: column;
		font-size: 3rem;
		width: 80%;
	}

	.heading {
		font-size: 3rem;

		@media screen and (max-width: 600px) {
			font-size: 2rem;
		}
		@media screen and (max-width: 300px) {
			font-size: 1.5rem;
		}
	}
}
